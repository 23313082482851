.form-reset-password-component {
  font-size: 12px;
  text-align: left;
  margin: 4px 0
}

.form-input-component {
  min-width: 300px !important
}

.form-label-component {
  font-weight: bold !important;
}

.form-label-component {
  @media (max-width: 767px) {
    display: none !important;
  }
}