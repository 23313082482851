$primaryDark: #03284D; //Pantone

.common-actions-menu-right {
  display: flex;
  justify-content: flex-end !important;
}

.menu-item-common-actions {
  background-color: $primaryDark !important;
  color: white !important;

  &:hover {
    opacity: 0.8;
  }
}