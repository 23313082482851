$spacing-unit: 8px;
$primaryColor: #002E5D; //Pantone
$warningColor: #cc3300; //Pantone

$primaryDark: #03284D; //Pantone
$secondaryColor: #00AEEF; //Pantone

$blue: #2185D0;
$white: #FFFFFF;

.common-table-header-color {
  background: $primaryDark !important;
  color: $white !important;

}

.common-table-icon-color {
  color: $primaryColor;
}

.common-table-warning-color {
  color: $warningColor;
}

.common-table-pagination-default {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  .ui {
    background: $primaryColor;

    .active {
      background-color: $white !important;
      color: $primaryDark !important;
      border-radius: 5px;
    }
  }
}