.login-container-image {
  height: 100%;
  display: block;
  background: #002E5D;
}

.content-images-login {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

}

.centered-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-form {
  width: 400px;
}

.relative {
  position: relative;
}

.image-login-content {
  height: 10%;
  position: absolute;
  top: 5%;
}


.logo-header-login {
  width: 6%;
  position: absolute;
  top: 4%;
  right: 4%;
}

.logo-header-login-left {
  width: 14%;
  position: absolute;
  top: 5%;
  left: 4%;

}



@media only screen and (min-width: 320px) and (max-width: 767px) {
  .mobile-only {
    display: unset;
  }

  .logo-header-login,
  .logo-header-login-left {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-only {
    display: none;
  }

  .image-login-content {
    display: none;
  }

}