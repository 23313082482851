.common-input-file-component {
  width: 70% !important;
}

.common-label-file-component {
  font-weight: bold !important;
  text-align: left;
  margin-bottom: .8px;
  font-size: 12px;

}

.button-file-only-mobile {
  display: none;
}


.common-error-container {
  height: 20px;
  display: flex;
}

.common-error-message {
  text-align: left;
  color: #9f3a38 !important;
  font-size: 10px;
  margin-top: 2px;
  font-weight: 700;
}



.common-input-file-component {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.common-button-file-component {
  @media (max-width: 767px) {
    border-radius: .28571429rem !important;
    width: 100%;
    height: 38px;
    overflow: hidden;
  }
}

.button-file-only-computer {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.button-file-only-mobile {
  @media (max-width: 767px) {
    display: inline-block;
  }
}

