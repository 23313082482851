.form-tab-content {
  overflow: initial;

  .tabular {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .tabular::-webkit-scrollbar {
    height: 5px;
  }

  .tabular::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
    padding-top: 50px;
  }


  .tabular::-webkit-scrollbar-thumb {
    background-color: #03284D;
    border-radius: 100px;
  }

}

.card-preview-gallery {
  height: 300px;
  width: 300px;
  overflow: hidden;

  .image {
    height: 250px !important;
    width: 100%;

    img {
      height: 100% !important;
      object-fit: cover;
    }

  }
}

.table-responsability-body-content {
  max-height: 350px !important;
  min-height: 50px;
  overflow: hidden;
  overflow-y: auto;
  display: grid;

  tr {
    border-bottom: 1px solid rgba(0, 0, 0, .1)
  }

  td {
    border-bottom: none !important;
    border-top: unset !important;
  }
}

.dimensioons-table-list {
  width: 35% !important;
  margin-left: 2rem;
}