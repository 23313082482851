.header-avatar-layout {
  margin-right: 40px !important;
}

.content-header-avatar-layout {
  border-radius: 50%;
  background: white;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-weight: bold;
    color: #03284d
  }
}

.header-dropdown-avatar-layout {
  text-align: center !important;

  .dropdown.icon {
    color: white
  }
}

.dropdown-options-layout {
  .icon {
    color: black !important
  }

  ;
}

.text-header-platform-bar {
  color: white;
  font-size: 1.2rem;
  text-align: center;
}

.active-menu-item {
  background-color: #f0f0f0;
}


.logo-header-layout {
  height: 63px;
  object-fit: contain;
}