$SizingHeader: 65px;
$SizingSidebar: 50px * 4;
$transition-duration: 0.5s;
$primaryDark: #03284D; //Pantone


.content-header-layout {
  height: $SizingHeader;
  background: $primaryDark !important
}

.image-header-layout {
  height: $SizingHeader * 1.05;
  margin-bottom: 0.8em;
}

.sidebar-container-component {
  width: $SizingSidebar !important;
  position: relative;
}


.sidebar-content-children {
  padding-top: 0 !important; //Margen con abse al headr
  padding-bottom: 50px !important;
  margin-left: $SizingSidebar; //!important; //Margen con base al sidebar
  transition: margin $transition-duration ease-in-out;

}

.item-sidebar-push-down {
  position: absolute !important;
  bottom: $SizingHeader * 1.2;
  right: 0;
  left: 0;
}
.item-sidebar-push-down-2 {
  position: absolute !important;
  bottom: $SizingHeader * 1.8;
  right: 0;
  left: 0;
}

.item-sidebar-push-down-3 {
  position: absolute !important;
  bottom: $SizingHeader * 2.4;
  right: 0;
  left: 0;
}

.ui.input.error > .description-text-area {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
  box-shadow: none;
}

.description-text-area {
  width: 100%;
  padding: 0.7rem 1rem;
  color: rgba(0,0,0,.87);
  border-radius: 0.5rem;
  outline: none;
  transition: box-shadow .1s ease, border-color .1s ease;
  box-shadow: none;
  border-color: #0002;
}
.description-text-area::placeholder {
  color: #8886;
}

.description-text-area:focus {
  border-color: #85b7d9 !important;
  background: #fff;
  box-shadow: none;
}
.description-text-area:focus::placeholder{
  color: #888F;
}

.sidebar-content-children {
  @media (max-width: 768px) {
    margin-left: 0 !important
  }
}

.sidebar-container-component {
  @media (max-width: 768px) {
    width: 100% !important
  }

}



.layout-general-container {
  @media (max-width: 768px) {
    margin-right: 0 !important;
  }

}