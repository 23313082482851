.form-container-signUp {
  width: 100%;

}

.form-container-signUp {
  @media (min-width: 1800px) {
    width: 50%;
  }
}


/* Agrega un estilo adicional para resoluciones específicas, como 1024x800 */
@media (min-width: 768px) and (max-width: 1280px) and (max-height: 800px) {
  .form-container-signUp {
    width: 100%;
  }
}