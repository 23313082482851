@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');


html, body {
  height: 100%;
  margin: 0;
  background: #f5f5f5 !important;
  font-family: 'Lato', sans-serif;
}

#root {
  height: 100vh !important;
  box-sizing: border-box !important;
  display: block; 
  margin: 0;
}

.max-200 {
  max-width: 220px;
  padding-top: 20px;
}