$spacing-unit: 8px;
$primaryColor: #002E5D; //Pantone
$primaryDark: #03284D; //Pantone
$blue: #2185D0;
$white: #FFFFFF;

//colors
:root {
  --primary-color: $primaryColor;
  --primary-dark: $primaryDark;
  --primary-light: #8a82ff;
  --secondary-color: #ffffff;
  --secondary-dark: #f4f4f4;
  --text-primary: #ffffff;
  --text-secondary: var(--primary-color);
  --negative-color: #e50024;
  --positive-color: #68baa5;
  --border-color: #aea9ba;
  --disabled: #494454;
  --grey: #a0a0a0;
  --dark: #000000;
  --white: $white;
}

/**buttons colors**/
.ui.primary.button,
.ui.primary.buttons .button {
  background: $primaryColor !important;
  color: var(--text-primary) !important;

  &:hover,
  &:focus,
  &:active {
    background: $primaryDark;
  }

  &:disabled {
    background: var(--grey);
  }
}

.ui.secondary.button,
.ui.secondary.buttons .button {
  color: $primaryColor;
  background: var(--text-primary);
  border: 1px solid $primaryColor;

  &:hover {
    background: var(--text-primary);
    color: $primaryColor;
  }

  &:disabled {
    background: var(--grey);
  }
}

.ui.basic.button,
.ui.basic.buttons .button {
  color: var(--dark) !important;
  background: var(--grey) !important;
}


.ui.input.error>input {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
  box-shadow: none;
}

i.inverted.bordered.blue.icon,
i.inverted.circular.blue.icon {
  background: $primaryColor !important;
}

//**Label**//

.ui.basic.label,
.ui.basic.labels .label {
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
}

/**Sidebar**/
.ui.sidebar {
  margin-top: 65px !important;
  background: $primaryDark !important;
}

.ui.inverted.icon.menu .item {
  color: $white !important;
}

/**header**/
// .ui.menu {
// background: $primaryDark;

// }


.ui.menu {
  a {
    color: $white !important;
  }
  .item:before{
    background: none !important;
  }
}


/**Tab*/

.ui.tabular.menu {

  .item {
    color: $primaryColor !important
  }

  .active.item {
    background: $primaryDark;
    color: white !important;
    border: none;
  }
}


// .ui.tabular.menu item {
//   background: $primaryDark;
//   color: white !important;
//   border: none;
// }





/**Text**/
.ui.header {
  color: $primaryColor !important;
}


.bg-red {
  color: red !important;
}

a {
  color: $primaryColor !important;
}

/*table */
.ui.table {
  font-size: 0.9em;
}

//**Atajos**//
.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around !important;
}

.justify-left {
  justify-content: left !important;
}

.justify-end {
  justify-content: end !important;
}

.align-center {
  align-items: center !important;
}

.align-end {
  align-items: end !important;
}

.align-start {
  align-items: start !important;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.p-0 {
  padding: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

// Margen superior
.mt-1 {
  margin-top: $spacing-unit !important;
}

.mt-2 {
  margin-top: $spacing-unit * 2 !important;
}

.mt-3 {
  margin-top: $spacing-unit * 3 !important;
}

.mt-4 {
  margin-top: $spacing-unit * 4 !important;
}

// Margen inferior
.mb-1 {
  margin-bottom: $spacing-unit !important;
}

.mb-2 {
  margin-bottom: $spacing-unit * 2 !important;
}

.mb-3 {
  margin-bottom: $spacing-unit * 3 !important;
}

.mb-4 {
  margin-bottom: $spacing-unit * 4 !important;
}

// Margen izquierdo
.ml-1 {
  margin-left: $spacing-unit !important;
}

.ml-2 {
  margin-left: $spacing-unit * 2 !important;
}

.ml-3 {
  margin-left: $spacing-unit * 3 !important;
}

.ml-4 {
  margin-left: $spacing-unit * 4 !important;
}

.ml-auto {
  margin-left: auto !important;
}

// Margen derecho
.mr-1 {
  margin-right: $spacing-unit !important;
}

.mr-2 {
  margin-right: $spacing-unit * 2 !important;
}

.mr-3 {
  margin-right: $spacing-unit * 3 !important;
}

.mr-4 {
  margin-right: $spacing-unit * 4 !important;
}

.mr-auto {
  margin-right: auto !important;
}

// Padding horizontal
.px-1 {
  padding-left: $spacing-unit !important;
  padding-right: $spacing-unit !important;
}

.px-2 {
  padding-left: $spacing-unit * 2 !important;
  padding-right: $spacing-unit * 2 !important;
}

.px-3 {
  padding-left: $spacing-unit * 3 !important;
  padding-right: $spacing-unit * 3 !important;
}

.px-4 {
  padding-left: $spacing-unit * 4 !important;
  padding-right: $spacing-unit * 4 !important;
}

// Padding vertical
.py-1 {
  padding-top: $spacing-unit !important;
  padding-bottom: $spacing-unit !important;
}

.py-2 {
  padding-top: $spacing-unit * 2 !important;
  padding-bottom: $spacing-unit * 2 !important;
}

.py-3 {
  padding-top: $spacing-unit * 3 !important;
  padding-bottom: $spacing-unit * 3 !important;
}

.py-4 {
  padding-top: $spacing-unit * 4 !important;
  padding-bottom: $spacing-unit * 4 !important;
}

// Padding individual
.p-1 {
  padding: $spacing-unit !important;
}

.p-2 {
  padding: $spacing-unit * 2 !important;
}

.p-3 {
  padding: $spacing-unit * 3 !important;
}

.p-4 {
  padding: $spacing-unit * 4 !important;
}

.text-center {
  text-align: center !important;
}

.text-bold {
  font-weight: bold !important;
}

.pointer {
  cursor: pointer;
}

.hidden-mobile {
  @media (max-width: 768px) {
    display: none !important;
  }
}