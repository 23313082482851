.container {
  padding: 0 2rem;
}

.custom-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.custom-header {
  font-size: 1.5rem;
  color: #9f3a38;
}

.custom-divider {
  margin: 10px 0;
  border-top: 1px solid #ccc;
}

.card-chart-footer {
  display: flex;
  justify-content: space-between;
}

.card-chart-footer .right-floated{
  color: black;
}

.summary-instalations {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem;
}

.info-icon-section {
  text-align: center;
  width: 100%;

  &__detail-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.columns-info-descriptions {
  display: grid !important;
}