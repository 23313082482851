.not-found-container {
  background-color: #002b5e;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  .not-found-header {
    font-size: 4em !important;
    margin-bottom: 20px;

    p {
      color: white !important;
    }
  }

  .not-found-text {
    font-size: 1.4rem;
    color: white;
    text-align: center;
    max-width: 600px;
    margin-bottom: 30px;
  }

  .not-found-link {
    color: white !important;
    font-size: 1.2rem;
    text-decoration: underline;
    cursor: pointer;
  }

  @media only screen and (max-width: 768px) {
    .not-found-header {
      font-size: 3em !important;
    }

    .not-found-text {
      font-size: 1rem;
    }
  }
}
