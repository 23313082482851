 .custom-button {
  padding: .88571429em 3em .88571429em !important;
  border-radius: 3px;
  border: 0;
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
}
.button > i {
  margin: 0 5px;
}

.ui.primary.button,.ui.primary.buttons .button{
  background: var(--primary-color);
  color: var(--text-primary);

}

.button-secondary {
  color: var(--primary-color);
  background: var(--text-primary);
  border: 1px solid var(--primary-color);
}

.button-primary:disabled {
  background: #6d6c6c;
}

.button-secondary:disabled {
  background: #e4587d;
}



.custom-button {
  @media (max-width: 767px) {
    width: 100% !important;
  }
}