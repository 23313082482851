.common-label-component {
  font-weight: bold !important;
  text-align: left;
  margin-bottom: .8px;
  font-size: 12px;
}


.common-label-component {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.common-error-container {
  height: 20px;
  display: flex;
}

.common-error-message {
  text-align: left;
  color: #9f3a38 !important;
  font-size: 10px;
  margin-top: 2px;
  font-weight: 700;
}